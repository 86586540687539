<template>
    <div class="row">
        <div class="col-md-8">
            <seccion-datos :titulo="datosperito" @cambios="guardar()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.nombre') }}</label>
                                <input type="text" v-model="nombre" class="form-control nombre_completo dato" id="nombre_completo" >
                            </div>
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.telefonofijouno') }}</label>
                                <input type="text" v-model="telefono1"  class="form-control nombre_completo dato" id="nombre_completo" >
                            </div>
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.telefonofijodos') }}</label>
                                <input type="text" v-model="telefono2"  class="form-control nombre_completo dato" id="nombre_completo" >
                            </div>
                            <div class="form-group">
                                <label for="nombre_completo">Fax </label>
                                <input type="text" v-model="fax"  class="form-control nombre_completo dato" id="nombre_completo" >
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.empresa') }}</label>
                                <input type="text" v-model="empresa"  class="form-control nombre_completo dato" id="nombre_completo" >
                            </div>
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.telefonomoviluno') }}</label>
                                <input type="text"  v-model="movil1" class="form-control nombre_completo dato" id="nombre_completo" >
                            </div>
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.telefonomovildos') }}</label>
                                <input type="text"  v-model="movil2" class="form-control nombre_completo dato" id="nombre_completo" >
                            </div>
                            <div class="form-group">
                                <label for="nombre_completo">Email </label>
                                <input type="text"  v-model="email" class="form-control nombre_completo dato" id="nombre_completo" >
                            </div>
                        </div>
                    </div>
                    </seccion-datos>
                </div>
            </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    components: {
    },
    props: ['id', 'datos'],
    data() {
        return {
            nombre: '',
            telefono1: '',
            telefono2: '',
            movil1: '',
            movil2: '',
            fax: '',
            empresa: '',
            email: '',
            datosperito: "Datos"
        }
    },
    methods: {
        async cargarDatosPerito() {
            this.$store.dispatch('cambiarCargando', true);
            this.nombre = this.datos.nombre;
            this.telefono1 = this.datos.telefono1;
            this.telefono2 = this.datos.telefono2;
            this.movil1 = this.datos.movil1;
            this.movil2 = this.datos.movil2;
            this.fax = this.datos.fax;
            this.empresa = this.datos.empresa;
            this.email = this.datos.email; 
            this.datosperito = this.$t("general.datos");   
            this.$store.dispatch('cambiarCargando', false);
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/peritos/:id */
            const datossubida = {nombre: this.nombre,telefono1: this.telefono1,telefono2: this.telefono2,movil1: this.movil1,movil2: this.movil2,fax: this.fax,empresa: this.empresa,email: this.email}
            const api = new PwgsApi;
            if (this.id > 0)
            {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('peritos/' + this.datos.id, datossubida);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$parent.cargarDatosPerito(this.id);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
            /* post  modulos/pwgsapi/index.php/peritos/  */
                try {
                    let res;
                    res = await api.post('peritos/', datossubida);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/peritos/' + res.id);
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }  
        }
    },
    watch: {
        id() {
            this.cargarDatosPerito(this.id);
        }
    },
    mounted() {
        this.cargarDatosPerito(this.id);
    }
}
</script>